import React, {useCallback} from 'react'
import { Button } from './Button';
import {useNavigate} from "react-router-dom";
import {RoutePath} from "@/pages/routes";

interface Props {
}

function Close(props: Props) {
  const navigate = useNavigate()
  const handleCloseClick = useCallback(() => {
    navigate({pathname: RoutePath.Main})
  }, [navigate])

  return (
    <Button group="image" variant="close" innerWrap={false} onClick={handleCloseClick}>닫기</Button>
  )
}

export { Close }
