import React from 'react';
import {useToast} from './hooks';
import {Toast} from "@/components/toast/Toast";

export const ToastContainer = () => {
    const { queue } = useToast();

    return (
        <div className={'toast_wrap'}>
            {queue.map((toast, index) => (
                <Toast key={toast.id} message={toast.message} active={toast.active} />
            ))}
        </div>
    )
}