import React, {lazy, Suspense} from 'react'
import {Container, GlobalLoading, ModalContainer} from './components'
import {RoutePath} from "@/pages/routes";
import {Navigate, Route, Routes} from "react-router-dom";
import {ValetErrorBoundary} from "@/components/error";
import {useSelector} from "react-redux";
import {selectAppAccessToken} from "@/store/app";
import {ToastContainer} from "@/components/toast";

const Login = lazy(() => import('@/pages/login/Login'))
const Main = lazy(() => import('@/pages/root/Main'))
const Settings = lazy(() => import('@/pages/settings/Settings'))
const DiscountApplyMain = lazy(() => import('@/pages/discount/apply/Main'))
const DiscountApplySubmit = lazy(() => import('@/pages/discount/apply/Submit'))
const Error = lazy(() => import('@/pages/error/Error'))

function App() {
    const accessToken = useSelector(selectAppAccessToken)

    return (
        <>
            {<GlobalLoading/>}
            <ModalContainer/>
            <ToastContainer/>
            <Container>
                <ValetErrorBoundary>
                    <Suspense fallback="">
                        <Routes>
                            {
                                accessToken ?
                                    (
                                        <>
                                            <Route path={RoutePath.Main} element={<Main/>}/>
                                            <Route path={RoutePath.Settings} element={<Settings/>}/>
                                            <Route path={RoutePath.DiscountApplyMain} element={<DiscountApplyMain/>}/>
                                            <Route path={RoutePath.DiscountApplySubmit} element={<DiscountApplySubmit/>}/>
                                            <Route path={RoutePath.Error} element={<Error/>}/>
                                            <Route
                                                path="*"
                                                element={<Navigate to={RoutePath.Main} replace />}
                                            />
                                        </>
                                    ) :
                                    (
                                        <>
                                            <Route path={RoutePath.Login} element={<Login/>}/>
                                            <Route path={RoutePath.Error} element={<Error/>}/>
                                            <Route
                                                path="*"
                                                element={<Navigate to={RoutePath.Login} replace />}
                                            />
                                        </>
                                    )
                            }
                        </Routes>

                    </Suspense>
                </ValetErrorBoundary>
            </Container>
        </>
    )
}

export default App
