import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient, RetailCouponData} from "@/axios";

/**
 * 보유한 할인권 목록 조회 API
 * @param params
 * @param config
 */
export function getRetailWebRetailCoupons(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>(`/api/retail-web/retail-coupons`, {
        ...restConfig,
        params: {
            ...restParams,
            ...configParams
        }
    })
}

interface Params {
}

interface ResponseData {
    message: RetailCouponData[];
}

