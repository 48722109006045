import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient, ValetParkingDataWithCoupon} from "@/axios";

/**
 * 차량번호 검색 API
 * @param params
 * @param config
 */
export function getRetailWebValetParking(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        plateNumber,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>(`/api/retail-web/valet-parking`, {
        ...restConfig,
        params: {
            plateNumber,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * plateNumber:     검색하고자 하는 차량번호 4자리
 */
interface Params {
    plateNumber: string;
}

/**
 *
 */
interface ResponseData {
    message: ValetParkingDataWithCoupon[];
}