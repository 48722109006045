import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient, ValetParkingDataWithCoupon} from "@/axios";

/**
 * 주차된 차량 정보 API
 * @param params
 * @param config
 */
export function getRetailWebValetParkingDetail(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        valetParkingId,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>(`/api/retail-web/valet-parking/${valetParkingId}`, {
        ...restConfig,
        params: {
            ...restParams,
            ...configParams
        }
    })
}

/**
 * valetParkingId:      주차된 차량의 발렛파킹 아이디
 */
interface Params {
    valetParkingId: string;
}

/**
 *
 */
interface ResponseData {
    message: ValetParkingDataWithCoupon;
}