import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { RootState } from '@/store'

type AppState = typeof initialState

interface Actions extends SliceCaseReducers<AppState> {
  setAccessToken(state: AppState, action: PayloadAction<string>): void;
}

const initialState = {
  accessToken: '',
}

export const appSlice = createSlice<AppState, Actions>({
  name: 'app',
  initialState,
  reducers: {
    setAccessToken(state, action) {
      const { payload } = action
      state.accessToken = payload
    },
  },
})

export const {
  setAccessToken,
} = appSlice.actions

export const selectAppAccessToken = (state: RootState) => state.app.accessToken