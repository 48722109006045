import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient, RetailCouponData} from "@/axios";

/**
 * 할인권 적용 API
 * @param params
 * @param config
 */
export function putRetailWebValetParkingCoupons(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        valetParkingId,
        coupons
    } = params
    const { params: configParams, ...restConfig } = config
    return axiosClient.put<ResponseData>(`/api/retail-web/valet-parking/${valetParkingId}/coupons`,
        {
            coupons,
            ...configParams,
        },
        {
            ...restConfig,
        },
    )
}

/**
 * valetParkingId:      주차된 차량의 발렛파킹 아이디
 * coupons:             적용할 쿠폰의 목록
 * * id:                쿠폰의 아이디
 * * quantity:          추가할 쿠폰의 수량 - 최종 수량이 아닌 추가할 수량을 전달
 */
interface Params {
    valetParkingId: number;
    coupons: Pick<RetailCouponData, 'id' | 'quantity'>[];
}

interface ResponseData {
    message: {},
}

