import React from 'react'
import {Back} from "../../components";

export default function Error() {
  return (
    <>
      <header className="error_header">
        <Back />
        <h1 className="error_header_title">서비스 오류</h1>
      </header>

      <section className="error_content">
        <div className="error_content_align">
          <h1 className="error_content_title">일시적인 서비스 오류입니다.</h1>
          <p className="error_content_msg">
            잠시 후 다시 시도해주세요.<br/>불편을 드려 죄송합니다.
          </p>
        </div>
      </section>
    </>
  )
}
