import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import Error from "@/pages/error/Error";

function ValetErrorBoundary({ children }: { children: React.ReactNode }) {
    return (
        <ErrorBoundary FallbackComponent={Error}>
            {children}
        </ErrorBoundary>
    )
}

export {ValetErrorBoundary}